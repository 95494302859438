import React, { useCallback, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../axios/config";
import * as PhoneVerificationActions from "./../../../redux/actions/PhoneVerificationActions";
import { logErrorToGrayLogs } from "../../../redux/actions/GrayLogActions";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import PropTypes from "prop-types";

const RegisterInformation = ({ handleSubmit, onSubmit, errors, register }) => {
  const [isPassword, setIsPassword] = useState(false);
  const [shouldRegister, setShouldRegister] = useState(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);

  const handleCaptchaChange = useCallback((value) => {
    logErrorToGrayLogs(`function: RegisterUser Captcha, platform: Web, Entered Values : ${value}`);
    if (value) {
      setShouldRegister(true);
    }
  }, []);

  return (
    <div className="signinWrapper">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <div className="loginWrapper">
              <h3>Step 1 of 3</h3>
              {/* <span className="eidtPhone">
                Thanks, we've sent a code to
                <i>
                  {props.phoneNumber}{" "}
                  <Link to="/register" onClick={() => props.onPhone()}>
                    {" "}
                    Edit
                  </Link>
                </i>
              </span> */}
              <form onSubmit={shouldRegister ? handleSubmit(onSubmit) : undefined}>
                <Row>
                  <Col md={12}>
                    {/* <div className="did-floating-label-content">
                      <SlRefresh className="refreshIcon" onClick={() => handleRefreshClick()} />
                      <input
                        {...register("code")}
                        className="did-floating-input"
                        type="text"
                        placeholder=" "
                      />
                      <label htmlFor="" className="did-floating-label">Code</label>
                      <span className="formErrorMsg">{errors.code?.message}</span>
                      <i>Resend code in 21s</i>
                    </div> */}

                    <div className="did-floating-label-content">
                      <input
                        {...register("phoneNumber")}
                        className="did-floating-input"
                        type="text"
                        placeholder=" "
                      />
                      <label htmlFor="phone" className="did-floating-label">Phone</label>
                      <span className="formErrorMsg">{errors.phoneNumber?.message}</span>
                    </div>

                    <div className="did-floating-label-content">
                      <input
                        {...register("name")}
                        className="did-floating-input"
                        type="text"
                        placeholder=" "
                      />
                      <label htmlFor="firstName" className="did-floating-label">First Name</label>
                      <span className="formErrorMsg">{errors.name?.message}</span>
                    </div>

                    <div className="did-floating-label-content">
                      <input
                        {...register("lastName")}
                        className="did-floating-input"
                        type="text"
                        placeholder=" "
                      />
                      <label htmlFor="lastName" className="did-floating-label">Last Name</label>
                      <span className="formErrorMsg">{errors.lastName?.message}</span>
                    </div>

                    <div className="did-floating-label-content">
                      <input
                        {...register("userName")}
                        className="did-floating-input"
                        type="text"
                        placeholder=" "
                      />
                      <label htmlFor="username" className="did-floating-label">Username</label>
                      <span className="formErrorMsg">{errors.userName?.message}</span>
                    </div>

                    <div className="did-floating-label-content">
                      <input
                        {...register("email")}
                        className="did-floating-input"
                        type="text"
                        placeholder=" "
                      />
                      <label htmlFor="email" className="did-floating-label">Email</label>
                      <span className="formErrorMsg">{errors.email?.message}</span>
                    </div>

                    <div className="did-floating-label-content">
                      {isPassword ? (
                        <AiFillEye onClick={() => setIsPassword(!isPassword)} />
                      ) : (
                        <AiOutlineEye onClick={() => setIsPassword(!isPassword)} />
                      )}
                      <input
                        {...register("password")}
                        className="did-floating-input"
                        type={`${isPassword ? "text" : "password"}`}
                        placeholder=" "
                      />
                      <label htmlFor="password" className="did-floating-label">Password</label>
                      {/* <ul className="passwordIcons">
                          <li><RxCross1 /> Minimum of 8 characters</li>
                          <li><RxCross1 /> Uppercase, lowercase letters, and one number</li>
                        </ul> */}
                      <span className="formErrorMsg">{errors.password?.message}</span>
                    </div>

                    <div className="did-floating-label-content">
                      {isConfirmPassword ? (
                        <AiFillEye onClick={() => setIsConfirmPassword(!isConfirmPassword)} />
                      ) : (
                        <AiOutlineEye onClick={() => setIsConfirmPassword(!isConfirmPassword)} />
                      )}
                      <input
                        {...register("confirmPassword")}
                        className="did-floating-input"
                        type={`${isConfirmPassword ? "text" : "password"}`}
                        placeholder=" "
                      />
                      <label htmlFor="confirmpassword" className="did-floating-label">Confirm Password</label>
                      <span className="formErrorMsg">{errors.confirmPassword?.message}</span>
                    </div>

                    <div className="did-floating-label-content">
                      <input
                        {...register("dob")}
                        className="did-floating-input"
                        type="date"
                        placeholder=" "
                      />
                      <label htmlFor="dateOfBirth" className="did-floating-label">Date of Birth</label>
                      <span className="formErrorMsg">{errors.dob?.message}</span>
                    </div>

                    <div className="did-floating-label-content">
                      <input
                        // {...register("signupcode")}
                        className="did-floating-input"
                        type="text"
                        placeholder=" "
                      />
                      <label htmlFor="instagram" className="did-floating-label">Instagram (Optional)</label>
                    </div>

                    <div className="did-floating-label-content" style={{ marginTop: ".5rem" }}>
                      <input
                        {...register("signupcode")}
                        className="did-floating-input"
                        type="text"
                        placeholder=" "
                      />
                      <label htmlFor="signupCode" className="did-floating-label">Sign up code (if you have one)</label>
                    </div>
                  </Col>
                </Row>
                <span className="loginTermsandCondition">
                  <Row>
                    <Col md={8}>
                      <input type="checkbox" name="agreement" />

                      <label htmlFor="vehicle1" style={{ display: "flex", flexDirection: "column" }}>
                        <i>Want a discount off your first order?</i>{" "}
                        Sign up to receive emails on the latest outfits and offers
                      </label>
                    </Col>
                  </Row>
                </span>
                <ReCAPTCHA
                  style={{ marginTop: 10 }}
                  onChange={handleCaptchaChange}
                  sitekey={config.REST_API.GoogleCaptcha.siteKey}
                />
                <span className="appBtnWrapperInner" style={{ marginBottom: "2rem" }}>
                  <input
                    disabled={!shouldRegister}
                    type="submit"
                    value="Create Account"
                  // onClick={registerinfohandler}
                  />
                </span>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    PhoneVerificationActions: bindActionCreators(PhoneVerificationActions, dispatch),
  };
};
RegisterInformation.propTypes = {
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.any,
  errors: PropTypes.any,
  register: PropTypes.any,
}
export default connect(null, mapDispatchToProps)(RegisterInformation);
