const ROOT_URL = "https://api.swished.app/api/";

const config = {
  REST_API: {
    Listing: {
      GetRecommendedProducts: `${ROOT_URL}v6.0/Product/GetAllListing`,
    },
    AppActions: {
      Login: `${ROOT_URL}v6/Account/LoginUserAccount`,
      Register: `${ROOT_URL}Web/v1.0/WebAccount/Register`,
      RegisterWithoutPhone: `${ROOT_URL}v6/Account/RegisterUserAccount`,
      ForgotPassword: `${ROOT_URL}v3.0/Account/ForgotPassword`,
      OtpVerification: `${ROOT_URL}v3.0/Account/OtpVerification`,
      OtpValidation: `${ROOT_URL}PhoneNumberVerification/verify-verification-code`,
      ResendOtpValidation: `${ROOT_URL}PhoneNumberVerification/refresh-user-verification-code`,
      ResetPassword: `${ROOT_URL}v3.0/Account/ResetPassword`,
      GetInstaAccessToken: `${ROOT_URL}Web/v1.0/WebAccount/GetInstaAccessToken`,
      CheckUserExists: `${ROOT_URL}Web/v1.0/WebAccount/CheckUserExists`,
      RegisterProfile: `${ROOT_URL}Profile/WebProfile`,
    },
    ExploreActions: {
      HeroCarousel: `${ROOT_URL}Carousel/GetCarouselArtifacts`,
      Collections: `${ROOT_URL}Collections`,
      CollectionProducts: `${ROOT_URL}v6/Product/GetCollectionProducts`,
    },
    ProfileActions: {
      getProfile: `${ROOT_URL}Profile/GetProfileByUserIdOrCode`,
      editProfile: `${ROOT_URL}Profile`,
      getWardrobe: `${ROOT_URL}v6.0/Product/GetMyWardrobes`,
    },
    productActions: {
      getProducts: `${ROOT_URL}v6.0/Product/GetAllListing`,
      getProductDetailById: `${ROOT_URL}v7/Product/GetProductByProductAndUserId`,
      setProductReservationDates: `${ROOT_URL}v7/ProductReservationDetails/post`,
      getProductReservationDates: `${ROOT_URL}v7/ProductReservationDetails/Get`,
      deleteProductReservationDates: `${ROOT_URL}v3/ProductReservationDetails`,
      AddToWishList: `${ROOT_URL}v7/WishList/AddToWishList`,
      RemoveFromWishList: `${ROOT_URL}v7/WishList/RemoveFromWishList`,
      GetMyWishList: `${ROOT_URL}v7/WishList/GetMyWishList`,
    },
    CloudSearchActions: {
      CloudSearch: `${ROOT_URL}Search`,
    },
    grayLog: {
      grayLogError: `${ROOT_URL}ClientSideExceptionLog`,
    },
    S3Upload: {
      GetPreSignedUrl: `${ROOT_URL}S3Upload`,
      GetS3Keys: `${ROOT_URL}S3Keys`,
    },
    StripeActions: {
      CreateUserPaymentIntent: `${ROOT_URL}v5/StripePayment/CreateConnectedAccountPaymentIntent`,
      CreateStripePaymentDetails: `${ROOT_URL}v7/StripePayment/PostV7`,
      ValidateReferral: `${ROOT_URL}v9/UserReferral/ValidateCode`,
      UserReferDetails: `${ROOT_URL}v8/UserReferral/GetMyReferralDetails`,
    },
    DeliveryOptions: {
      GetDeliveryDetails: `${ROOT_URL}DeliveryOptions`,
    },
    PhoneNumberVerification: {
      SendPhoneNumberVerification: `${ROOT_URL}v3.0/Account/send-verification-code`,
      RefreshPhoneNumberVerification: `${ROOT_URL}PhoneNumberVerification/refresh-verification-code`,
    },
    Messages: {
      getFcmToken: `${ROOT_URL}PushNotification`,
      userCollection: "users",
      chatCollection: "chatRooms",
      firebaseApiKey: "AIzaSyDVBUN7PkNh5hoheK90Q8cavPygywmPPSM",
    },
    GiftCards: {
      getGiftAmounts: `${ROOT_URL}GiftCard/list`,
      postPaymentIntent: `${ROOT_URL}GiftCard/CreateGiftCardPaymentIntent`,
      postCardDetails: `${ROOT_URL}GiftCard/save-purchase-detail`,
    },
    GoogleCaptcha: {
      siteKey: "6LfjrCgmAAAAADTAuohmPkdfYwGUGVDWu6kiLYRM",
    },
  },
  S3_URL: "https://swished-dev.s3.eu-west-2.amazonaws.com",
};

export default config;
