import axios from "axios";
import Config from "../../axios/config";
import { logErrorToGrayLogs } from "./GrayLogActions";

const { REST_API } = Config;
export const CreateUserPaymentIntent = (filters) => (dispatch) => {
  return axios
    .post(REST_API.StripeActions.CreateUserPaymentIntent, filters, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      if (baseModel.success) {
        return baseModel;
      }
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: CreateUserPaymentIntent, platform: Web, client error msg: ${error.message}`
      );
      return {
        success: false,
      };
    });
};
export const CreateStripePaymentDetails = (dataObj) => (dispatch) => {
  return axios
    .post(REST_API.StripeActions.CreateStripePaymentDetails, dataObj, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      if (baseModel.success) {
        return baseModel;
      }
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: CreateStripePaymentDetails, platform: Web, client error msg: ${error.message}`
      );
      return {
        success: false,
      };
    });
};

export const verifyCouponOrReferralCode =
  (discountCode, reservationId, userId, rentalPrice) => (dispatch) => {
    let url;
    if (rentalPrice) {
      url = `${REST_API.StripeActions.ValidateReferral}/${discountCode}/${reservationId}/${userId}?rentalPrice=${rentalPrice}`;
    } else {
      url = `${REST_API.StripeActions.ValidateReferral}//${discountCode}/${reservationId}/${userId}`;
    }
    return axios
      .post(url, { headers: { "Content-Type": "application/json; charset=utf-8" } })
      .then((response) => {
        const baseModel = response.data;
        return baseModel;
      })
      .catch((error) => {
        logErrorToGrayLogs(
          `function: verifyCouponOrReferralCode, platform: Web, client error msg: ${error.message}`
        );
        return {
          success: false,
        };
      });
  };

export const getMyReferralDetails = (payload) => (dispatch) => {
  return axios
    .get(`${REST_API.StripeActions.UserReferDetails}`, {
      params: payload,
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      const baseModel = response.data;
      return baseModel;
    })
    .catch((error) => {
      logErrorToGrayLogs(
        `function: getMyReferralDetails, platform: Web, client error msg: ${error.message}`
      );
      return {
        success: false,
      };
    });
};
