import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

import QrCode from "../../common/checkout/QrCode";
import { appstorebtn, googleplaybtn } from "../../../images";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";

const GiftPaymentSuccess = (props) => {
  const { giftData } = props;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!Object.keys(giftData).length) {
      navigate("/gift-cards");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftData]);

  return (
    <div className="paymentWrapper">
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <h3>Thank you for your purchase</h3>
            <p>
              We'll let {giftData?.recipientName} know that you've sent them a gift on{" "}
              {moment(giftData?.deliveryDate).format("DD MMMM YYYY")}
            </p>
            <p>Click on this image from your phone or scan the QR code to download the Swished app.</p>

            <span className="playstoreWrapper">
              <span className="downloadBtn">Download the Swished app now</span>
              <div className="QeCodeWrapper">
                <span>
                  <a
                    href="https://apps.apple.com/gb/app/swished/id1600685718"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appstorebtn} alt="" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=co.uk.swished"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={googleplaybtn} alt="" />
                  </a>
                </span>
                <span>
                  <QrCode />
                </span>
              </div>
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ gift }) => ({
  giftData: gift.giftCardData,
});

GiftPaymentSuccess.propTypes = {
  giftData: PropTypes.any,
}

export default connect(mapStateToProps)(GiftPaymentSuccess);
