export const Basket = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="100.000000pt"
      height="90.000000pt"
      viewBox="0 0 222.000000 190.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,190.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M919 1610 c-80 -24 -129 -99 -129 -198 l0 -62 -135 0 -135 0 0 -283 c0 -326 5 -356 71 -431 80 -91 79 -91 442 -94 366 -4 393 0 471 69 90 80 91 80 94 432 l3 307 -105 0 -106 0 0 -45 0 -45 60 0 60 0 0 -242 c0 -264 -6 -294 -61 -345 -44 -40 -69 -43 -387 -43 -277 0 -311 2 -347 19 -46 21 -79 54 -94 94 -7 17 -11 124 -11 272 l0 244 298 3 299 3 41 29 c84 58 104 164 46 248 -44 62 -90 78 -230 77 -65 0 -131 -4 -145 -9z m290 -106 c40 -33 42 -79 5 -123 l-26 -31 -154 0 -154 0 0 59 c0 49 4 65 26 90 l26 31 123 0 c118 0 125 -1 154 -26z" />
      </g>
    </svg>
  );
};

export const MainLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      width="545.753"
      height="95.882"
      viewBox="0 0 545.753 95.882"
    >
      <g>
        <path
          d="m75.136,82.714c-4.545,0-8.784-.336-12.72-1.008s-7.392-1.824-10.368-3.456c-2.976-1.632-5.313-3.903-7.008-6.816-1.696-2.912-2.544-6.576-2.544-10.992v-.528c0-.224.032-.463.096-.72h20.064c-.064.257-.096.497-.096.72v.624c0,1.92.447,3.393,1.344,4.416.896,1.024,2.16,1.713,3.792,2.064,1.632.353,3.471.528,5.52.528.768,0,1.584-.015,2.448-.048.864-.032,1.695-.127,2.496-.288.799-.159,1.536-.399,2.208-.72.672-.32,1.2-.752,1.584-1.296.384-.543.576-1.231.576-2.064,0-1.215-.544-2.175-1.632-2.88-1.089-.704-2.561-1.311-4.416-1.824-1.857-.511-3.936-.991-6.24-1.44-2.304-.447-4.704-.975-7.2-1.584-2.496-.607-4.896-1.392-7.2-2.352-2.304-.96-4.385-2.191-6.24-3.696-1.857-1.503-3.329-3.392-4.416-5.664-1.089-2.271-1.632-5.007-1.632-8.208,0-3.84.799-7.104,2.4-9.792,1.599-2.688,3.792-4.863,6.576-6.528,2.784-1.664,5.952-2.88,9.504-3.648,3.552-.768,7.279-1.152,11.184-1.152,3.967,0,7.695.401,11.184,1.2,3.487.801,6.576,2.033,9.264,3.696,2.688,1.665,4.8,3.777,6.336,6.336,1.536,2.56,2.335,5.601,2.4,9.12v1.152h-19.872v-.384c0-1.152-.288-2.239-.864-3.264-.576-1.023-1.488-1.855-2.736-2.496-1.248-.639-2.896-.96-4.944-.96-1.92,0-3.537.192-4.848.576-1.312.384-2.289.912-2.928,1.584-.64.672-.96,1.392-.96,2.16,0,1.216.543,2.208,1.632,2.976,1.087.768,2.559,1.408,4.416,1.92,1.855.513,3.936.993,6.24,1.44,2.304.449,4.704.945,7.2,1.488,2.496.544,4.896,1.264,7.2,2.16,2.304.897,4.383,2.064,6.24,3.504,1.855,1.44,3.327,3.216,4.416,5.328,1.087,2.112,1.632,4.704,1.632,7.776,0,5.441-1.265,9.856-3.792,13.248-2.529,3.393-5.985,5.873-10.368,7.44-4.384,1.568-9.36,2.352-14.928,2.352Z"
          fill="#ea0000"
        />
        <path
          d="m123.615,81.562l-15.456-66.048h22.08l5.76,28.224c.127.513.288,1.296.48,2.352.192,1.056.416,2.208.672,3.456.255,1.248.48,2.385.672,3.408h.384c.127-.576.271-1.263.432-2.064.159-.8.319-1.616.48-2.448.159-.831.319-1.664.48-2.496.159-.831.303-1.567.432-2.208l5.664-28.224h20.928l6.048,28.224c.127.641.303,1.505.528,2.592.224,1.089.463,2.225.72,3.408.255,1.185.48,2.256.672,3.216h.384c.127-.639.271-1.359.432-2.16.159-.799.303-1.615.432-2.448.127-.831.271-1.647.432-2.448.159-.799.303-1.52.432-2.16l5.376-28.224h21.12l-15.456,66.048h-23.328l-6.528-30.912c-.129-.831-.321-1.808-.576-2.928-.256-1.119-.513-2.256-.768-3.408-.256-1.152-.48-2.144-.672-2.976h-.384c-.192.768-.4,1.713-.624,2.832-.225,1.121-.465,2.241-.72,3.36-.256,1.121-.48,2.16-.672,3.12l-6.528,30.912h-23.328Z"
          fill="#ea0000"
        />
        <path d="m210.783,81.562V15.514h21.216v66.048h-21.216Z" fill="#ea0000" />
        <path
          d="m275.869,82.714c-4.545,0-8.784-.336-12.72-1.008s-7.392-1.824-10.368-3.456c-2.976-1.632-5.313-3.903-7.008-6.816-1.696-2.912-2.544-6.576-2.544-10.992v-.528c0-.224.032-.463.096-.72h20.064c-.064.257-.096.497-.096.72v.624c0,1.92.447,3.393,1.344,4.416.896,1.024,2.16,1.713,3.792,2.064,1.632.353,3.471.528,5.52.528.768,0,1.584-.015,2.448-.048.864-.032,1.695-.127,2.496-.288.799-.159,1.536-.399,2.208-.72.672-.32,1.2-.752,1.584-1.296.384-.543.576-1.231.576-2.064,0-1.215-.544-2.175-1.632-2.88-1.089-.704-2.561-1.311-4.416-1.824-1.857-.511-3.936-.991-6.24-1.44-2.304-.447-4.704-.975-7.2-1.584-2.496-.607-4.896-1.392-7.2-2.352-2.304-.96-4.385-2.191-6.24-3.696-1.857-1.503-3.329-3.392-4.416-5.664-1.089-2.271-1.632-5.007-1.632-8.208,0-3.84.799-7.104,2.4-9.792,1.599-2.688,3.792-4.863,6.576-6.528,2.784-1.664,5.952-2.88,9.504-3.648,3.552-.768,7.279-1.152,11.184-1.152,3.967,0,7.695.401,11.184,1.2,3.487.801,6.576,2.033,9.264,3.696,2.688,1.665,4.8,3.777,6.336,6.336,1.536,2.56,2.335,5.601,2.4,9.12v1.152h-19.872v-.384c0-1.152-.288-2.239-.864-3.264-.576-1.023-1.488-1.855-2.736-2.496-1.248-.639-2.896-.96-4.944-.96-1.92,0-3.537.192-4.848.576-1.312.384-2.289.912-2.928,1.584-.64.672-.96,1.392-.96,2.16,0,1.216.543,2.208,1.632,2.976,1.087.768,2.559,1.408,4.416,1.92,1.855.513,3.936.993,6.24,1.44,2.304.449,4.704.945,7.2,1.488,2.496.544,4.896,1.264,7.2,2.16,2.304.897,4.383,2.064,6.24,3.504,1.855,1.44,3.327,3.216,4.416,5.328,1.087,2.112,1.632,4.704,1.632,7.776,0,5.441-1.265,9.856-3.792,13.248-2.529,3.393-5.985,5.873-10.368,7.44-4.384,1.568-9.36,2.352-14.928,2.352Z"
          fill="#ea0000"
        />
        <path
          d="m315.516,81.562V15.514h21.216v24.096h23.329V15.514h21.217v66.048h-21.217v-25.056h-23.329v25.056h-21.216Z"
          fill="#ea0000"
        />
        <path
          d="m395.771,81.562V15.514h57.12v15.84h-35.903v9.12h30.72v15.168h-30.72v10.08h36.575v15.84h-57.792Z"
          fill="#ea0000"
        />
        <path
          d="m464.794,81.562V15.514h28.608c7.551,0,13.887,1.185,19.008,3.552,5.12,2.369,9.008,5.985,11.664,10.848,2.655,4.864,3.984,11.073,3.984,18.624,0,7.488-1.329,13.68-3.984,18.576-2.656,4.896-6.544,8.529-11.664,10.896-5.121,2.368-11.457,3.552-19.008,3.552h-28.608Zm21.217-15.84h7.008c2.304,0,4.304-.32,6-.96,1.695-.639,3.087-1.568,4.176-2.784,1.088-1.215,1.887-2.72,2.4-4.512.511-1.791.768-3.807.768-6.048v-5.76c0-2.24-.257-4.256-.768-6.048-.514-1.791-1.312-3.295-2.4-4.512-1.089-1.215-2.48-2.144-4.176-2.784-1.696-.639-3.696-.96-6-.96h-7.008v34.368Z"
          fill="#ea0000"
        />
      </g>
      <path
        d="m14.585,71.091c0-6.362,5.066-11.663,11.545-11.663s11.546,5.301,11.546,11.663c0,6.244-5.066,11.311-11.546,11.311s-11.545-5.066-11.545-11.311Z"
        fill="#ea0000"
      />
    </svg>
  );
};

export const Envalop = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100px"
      height="100px"
      version="1.1"
      viewBox="0 0 700 700"
    >
      <g>
        <path d="m565.97 459.66h-431.94c-27.586 0-50.031-22.445-50.031-50.031v-259.24c0-27.586 22.445-50.031 50.031-50.031h431.93c27.586 0 50.031 22.445 50.031 50.031v259.24c0.003906 27.582-22.441 50.031-50.027 50.031zm-431.94-328.67c-10.695 0-19.395 8.6992-19.395 19.395v259.24c0 10.695 8.6992 19.395 19.395 19.395h431.93c10.695 0 19.395-8.6992 19.395-19.395v-259.24c0-10.695-8.6992-19.395-19.395-19.395z" />
        <path d="m350 293.3c-9.1758 0-18.355-2.2891-26.543-6.875l-20.832-11.664c-0.45312-0.25-0.88672-0.52734-1.3125-0.82812l-195.86-137.38c-6.9258-4.8594-8.6055-14.41-3.7422-21.336 4.8516-6.9375 14.41-8.6133 21.336-3.7422l195.23 136.93 20.156 11.281c7.1367 4.0039 16.004 4 23.141 0l20.156-11.281 195.23-136.93c6.9297-4.8516 16.477-3.1836 21.336 3.7422 4.8633 6.9258 3.1836 16.477-3.7422 21.336l-195.87 137.38c-0.42578 0.30078-0.85938 0.57812-1.3125 0.82812l-20.832 11.664c-8.1836 4.5859-17.363 6.875-26.539 6.875z" />
        <path d="m110.74 448.23c-4.3086 0-8.5898-1.8047-11.621-5.3242-5.5156-6.4141-4.7891-16.086 1.6211-21.609l199.38-171.52c6.4141-5.5156 16.086-4.793 21.609 1.6211 5.5156 6.4141 4.7891 16.086-1.6211 21.609l-199.38 171.52c-2.8945 2.4844-6.4492 3.7031-9.9883 3.7031z" />
        <path d="m589.26 448.23c-3.543 0-7.0938-1.2188-9.9883-3.7031l-199.38-171.52c-6.4102-5.5234-7.1367-15.195-1.6211-21.609 5.5234-6.4141 15.195-7.1328 21.609-1.6211l199.38 171.52c6.4102 5.5234 7.1367 15.195 1.6211 21.609-3.0312 3.5234-7.3125 5.3281-11.621 5.3281z" />
      </g>
    </svg>
  );
};

export const Heart = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="700pt"
      height="700pt"
      version="1.1"
      viewBox="0 0 700 700"
    >
      <g>
        <path d="m545.44 113.68c-24.078-24.078-54.879-34.719-86.238-34.719-38.641 0-78.398 16.238-108.08 45.359 0 0-0.55859 0.55859-1.1211 0.55859-0.55859 0-0.55859 0-1.1211-0.55859-26.879-25.199-61.602-50.398-96.879-50.398-35.281 0-70.559 13.441-97.441 40.32-53.762 53.762-53.762 141.68 0 195.44l183.68 173.04c3.3594 2.8008 7.2812 4.4805 11.762 4.4805 3.9219 0 8.3984-1.6797 11.762-4.4805l183.68-173.04c26.875-27.445 39.754-63.285 39.754-98.562 0-35.281-12.879-70.562-39.758-97.441zm-19.602 175.28-175.84 165.2-175.84-165.2c-20.16-20.723-31.359-48.16-31.359-77.84 0-29.68 11.199-57.121 31.922-77.84 20.719-20.719 48.16-31.922 77.84-31.922 21.281 0 48.16 14.559 77.281 42.559 5.6016 5.0391 12.879 7.8398 20.16 7.8398 7.8398 0 15.121-2.8008 20.719-8.3984 24.078-23.52 57.121-36.961 88.48-36.961 17.922 0 44.238 4.4805 66.641 26.879 20.156 20.723 31.355 48.723 31.355 77.844 0 29.68-11.199 57.117-31.359 77.84z" />
      </g>
    </svg>
  );
};

export const FillHeart = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="700pt"
      height="700pt"
      version="1.1"
      viewBox="0 0 700 700"
    >
      <g>
        <path
          d="m545.44 113.68c-24.078-24.078-54.879-34.719-86.238-34.719-38.641 0-78.398 16.238-108.08 45.359 0 0-0.55859 0.55859-1.1211 0.55859-0.55859 0-0.55859 0-1.1211-0.55859-26.879-25.199-61.602-50.398-96.879-50.398-35.281 0-70.559 13.441-97.441 40.32-53.762 53.762-53.762 141.68 0 195.44l183.68 173.04c3.3594 2.8008 7.2812 4.4805 11.762 4.4805 3.9219 0 8.3984-1.6797 11.762-4.4805l183.68-173.04c26.875-27.445 39.754-63.285 39.754-98.562 0-35.281-12.879-70.562-39.758-97.441z"
          fill="#ffb2c3"
        />
      </g>
    </svg>
  );
};

export const ImageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
      <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
    </svg>
  );
};
