import axios from "axios";
import config from "../resources/axios/config";
import keys from "../resources/utilities/keys";
import { logErrorToGrayLogs } from "../resources/redux/actions/GrayLogActions";

export const getAllProducts = async (params) => {
  let { pageParam, resale, sort, filter1, filter2, filter3, id, UserId } = params;
  let filters;
  if (id) {
    filters = `${filter1}${filter2}${filter3}&${resale}&SortBy=${sort}&Current=${pageParam}&Limit=${keys.limit}&collectionId=${id}`;
  } else {
    filters = `${filter1}${filter2}${filter3}&${resale}&SortBy=${sort}&Current=${pageParam}&Limit=${keys.limit}`;
  }

  const response = await axios.get(
    `${config.REST_API.productActions.getProducts}?UserId=${UserId}&${filters}`,
    {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    }
  );
  let result = {
    data: response?.data?.data?.data ? response?.data?.data?.data : [],
    nextPage: response?.data?.data?.nextPage,
  };
  return result;
};

export const getSearchProducts = async (params) => {
  try {
    const response = await fetch(
      `https://ilg0366s55.execute-api.eu-west-1.amazonaws.com/staging?${new URLSearchParams(
        params
      )}`
    );

    const data = await response.json();
    if (response && response.status === 200) {
      return {
        result: data?.hits?.hit,
        found: data?.hits?.found,
        start: data?.hits?.start + 20,
        hasMoreData: data?.hits?.found - (data?.hits?.start + data?.hits?.hit.length) > 0,
        nextPage: data?.hits?.start + 1,
      };
    }
  } catch (error) {
    logErrorToGrayLogs(
      `function: getProductsBySearchTerm, platform: Web, client error msg: ${error.message}`
    );
    return {
      success: false,
    };
  }
};

export const getWishList = async (payload) => {
  const { limit, userId, current } = payload;
  let filters = `userId=${userId}&Current=${current}&limit=${limit}`;
  const response = await axios.get(`${config.REST_API.productActions.GetMyWishList}?${filters}`);

  if (response && response.status === 200) {
    return {
      data: response.data.data.data,
      hasMore: response.data.data.hasMore,
      nextPage: response.data.data.nextPage,
    };
  }
};

export const getWardrobe = async (payload) => {
  const { limit, userId, current } = payload;
  let filters = `userId=${userId}&Current=${current}&limit=${limit}`;
  const response = await axios.get(`${config.REST_API.ProfileActions.getWardrobe}?${filters}`);

  if (response && response.status === 200) {
    return {
      data: response.data.data?.data,
      hasMore: response.data.data?.hasMore,
      nextPage: response.data.data?.nextPage,
    };
  }
};

export const removeFromWishList = async (data) => {
  const { userId, productId } = data;
  const result = await axios.delete(
    `${config.REST_API.productActions.RemoveFromWishList}?userId=${userId}&productId=${productId}`
  );
  return result;
};

export const addToWishList = async (data) => {
  const result = await axios.post(config.REST_API.productActions.AddToWishList, data);
  return result;
};

export const getProductById = async (productId, userId) => {
  let result = await axios.get(
    `${config.REST_API.productActions.getProductDetailById}?productId=${productId}&userId=${userId}`,
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );

  return result.data;
};
