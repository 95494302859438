import React from "react";
import { Link } from "react-router-dom";

const BannerStrip = () => {
  return (
    <div className="headerSrtip">
      <Link to="https://onelink.to/swished" target="_blank">
        To track your orders or make money listing your clothes, tap to download the Swished app.
      </Link>
    </div>
  );
};

export default BannerStrip;
