import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";

const InputField = ({ id, type, placeholder, error, onchange, value, onKeyDown }) => {
  return (
    <Form>
      <Form.Group className="fieldError" controlId={id}>
        <Form.Control
          type={type}
          placeholder={placeholder}
          onChange={onchange}
          value={value || ""}
          onKeyDown={onKeyDown}
        />
        <div className="fieldError">{error}</div>
      </Form.Group>
    </Form>
  );
};

InputField.propTypes = {
  id: PropTypes.any,
  type: PropTypes.any,
  placeholder: PropTypes.any,
  error: PropTypes.any,
  onchange: PropTypes.any,
  value: PropTypes.any,
  onKeyDown: PropTypes.any
}

export default InputField;
