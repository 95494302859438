import {
  doc,
  updateDoc,
  arrayUnion,
  serverTimestamp,
  getDoc,
  setDoc,
  getDocs,
  query,
  collection,
  where,
  limit,
} from "firebase/firestore";
import { isEmpty } from "lodash";
import { db } from "../firebase";
import config from "../resources/axios/config";
import axios from "axios";

let userCollection = config.REST_API.Messages.userCollection;
let chatCollection = config.REST_API.Messages.chatCollection;

const userEmail = localStorage.getItem("userEmail");

const getChatId = async (myUser, otherUser, product = null) => {
  let imageUri =
    "https://swished-dev.s3.amazonaws.com/swished-artifacts%2F1A7BD11D-A37B-4961-B05A-A63E9E7611E1.jpg";
  if (!isEmpty(product)) {
    imageUri = product.image;
  }

  if (isEmpty(myUser) || isEmpty(otherUser) || myUser.email === otherUser.email) {
    return null;
  }

  // check if both user exist in firebase
  let myUserDocument;
  let otherUserDocument;

  // check if my own user exists in users collection
  const userQuery = query(
    collection(db, userCollection),
    where("email", "==", myUser.email || userEmail),
    limit(1)
  );
  myUserDocument = await getDocs(userQuery);
  if (myUserDocument.empty) {
    await setDoc(doc(db, userCollection, myUser.email || userEmail), {
      name: myUser.name,
      email: myUser.email || userEmail,
      userId: myUser.userId,
    });
  }

  // check if other user exists in users collection
  const otherUserQuery = query(
    collection(db, userCollection),
    where("email", "==", otherUser.email),
    limit(1)
  );
  otherUserDocument = await getDocs(otherUserQuery);
  if (otherUserDocument.empty) {
    await setDoc(doc(db, userCollection, otherUser.email), {
      name: otherUser.name,
      email: otherUser.email,
      userId: otherUser.userId,
    });
  }

  // // If either user is new to Firebase, they won't have any chat history.
  const chatId = generateChatId(myUser, otherUser, product);

  const chatDocument = await getDoc(doc(db, chatCollection, chatId));
  if (!chatDocument.exists()) {
    const chatCreationTime = serverTimestamp();

    let chatPayload = {
      users: [
        {
          name: myUser.name,
          email: myUser.email || userEmail,
          userId: myUser.userId,
        },
        {
          name: otherUser.name,
          email: otherUser.email,
          userId: otherUser.userId,
        },
      ],
      messages: [],
      lastUpdated: chatCreationTime,
      unseenMessageCount: [
        {
          email: myUser.email || userEmail,
          count: 0,
        },
        {
          email: otherUser.email,
          count: 0,
        },
      ],
      userEmails: [myUser.email || userEmail, otherUser.email],
    };

    if (!isEmpty(product)) {
      chatPayload.product = {
        id: product.id,
        title: product.title,
        image: product.image ? product.image : imageUri,
      };
    }

    await Promise.all([
      setDoc(doc(db, chatCollection, chatId), chatPayload),
      updateDoc(doc(db, userCollection, myUser.email || userEmail), {
        chatIds: arrayUnion(chatId),
      }),
      updateDoc(doc(db, userCollection, otherUser.email), {
        chatIds: arrayUnion(chatId),
      }),
    ]);
  } else {
    const existingChatData = chatDocument.data();
    if (!existingChatData.userEmails) {
      const userEmails = [myUser.email, otherUser.email];
      await updateDoc(doc(db, chatCollection, chatId), { userEmails });
    }
  }

  return chatId;
};

const generateChatId = (myUser, otherUser, product) => {
  const email = myUser.email || userEmail;
  let chatId =
    email < otherUser.email ? `${email}-${otherUser.email}` : `${otherUser.email}-${email}`;

  if (!isEmpty(product)) {
    chatId = `${chatId}-${product.id}`;
  }

  return chatId;
};

export const getFCMTokenFromApi = async (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(config.REST_API.Messages.getFcmToken, {
        params: payload,
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default {
  getChatId,
  getFCMTokenFromApi,
};
