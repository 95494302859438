import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DeliveryDetail from "./DeliveryDetail";
import Rentaldetail from "./Rentaldetail";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as StripeActions from "../../../redux/actions/StripeActions";
import * as ProductActions from "../../../redux/actions/productActions";
import * as DeliveryOptionsActions from "../../../redux/actions/DeliveryOptionsActions";
import CheckoutForm from "./CheckoutForm";
import { Common } from "./../../../utilities/helpers";
import { sendAmplitudeData } from "../../../../utils/amplitude";
import PropTypes from "prop-types";

const stripePromise = loadStripe(
  "pk_live_51KYT5kE1UVqYEWXV20YpqgQ0tK7rvwhVgZhnDgOpzsY2izLtrK7sJjfKhrIOBsu1P55BpWCZ1gjLJTSAKyPZnVoO00Tb5M6XKY"
);

const Checkout = (props) => {
  const { currentReservation, userId, deliveryOptions } = props;

  const [clientSecret, setClientSecret] = useState("");
  const [state, setState] = useState({});

  const navigate = useNavigate();

  const location = useLocation();
  const productId = location.state.productId;
  const productName = location.state.name;
  const productPicture = location.state.picture;
  const productSize = location.state.size;
  const arriveDate = location.state.arrivedate;
  const arriveMonth = location.state.arrivemonth;
  const postbackDate = location.state.postbackdate;
  const postbackMonth = location.state.postbackmonth;
  const rentalPrice = location.state.rentalPrice;
  const isPurchased = location.state.isPurchased;
  const resalePrice = location.state.resalePrice;

  const [selectedDelivery, setSelectedDelivery] = useState();
  const [selectedDeliveryText, setSelectedDeliveryText] = useState();
  const [selectedDeliveryAmount, setSelectedDeliveryAmount] = useState();

  const [isBillingAddress, setIsBillingAddress] = useState(false);

  const [deliveryErrors, setDeliveryErrors] = useState({});
  const [discountMessage, setDiscountMessage] = useState({ show: false, isError: false, text: "" });
  const [useReferralCredit, setUseReferralCredit] = useState(false);
  const [userReferralCredit, setUserReferralCredit] = useState(0);

  const success = useRef();
  success.current = false;

  const calculateStressFreePrice = () => {
    // damage fee is 10% of the rental price or 5 pound if exceeding 5.
    let sfAmount = rentalPrice?.price * 0.1;
    if (sfAmount > 5) sfAmount = 5;
    return parseFloat(sfAmount).toFixed(2);
  };
  const calculatedStressFreePrice = calculateStressFreePrice();
  const [stressFreePrice, setStressFreePrice] = useState(0);
  const [selectedStressFree, setSelectedStressFree] = useState("no");

  const [discount, setDiscount] = useState({ discountCode: "", discountValue: 0, type: "" });
  const [referralDiscount, setReferralDiscount] = useState({
    discountValue: 0,
    type: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setState((state) => ({ ...state, daCountry: "GB" }));
    setState((state) => ({ ...state, billingCountry: "GB" }));
    props.DeliveryOptionsActions.getDeliveryOptions().then((response) => {
      if (response.success) {
        setSelectedDelivery(response.data[0].id);
        setSelectedDeliveryText(response.data[0].option);
        setSelectedDeliveryAmount(response.data[0].value);
      }
    });
    fetchReferralDetails();
  }, []);

  useEffect(() => {
    return () => {
      if (success.current === false) {
        props.ProductActions.deleteProductReservationDates(currentReservation.id);
      }
    };
  }, []);

  const calculateTotalPrice = () => {
    let totalAmount;
    if (discount?.discountType === "Gift Card") {
      totalAmount =
        Number(isPurchased ? resalePrice : rentalPrice?.price) +
        Number(selectedDeliveryAmount) +
        Number(isPurchased ? 0 : stressFreePrice);
      totalAmount =
        totalAmount - discount?.discountValue > 0 ? totalAmount - discount?.discountValue : 0;
    } else {
      totalAmount =
        Number(isPurchased ? resalePrice : rentalPrice?.price) -
        Number(discount.discountValue) +
        Number(selectedDeliveryAmount) +
        Number(isPurchased ? 0 : stressFreePrice);
    }
    return totalAmount;
  };

  const calculateTotalPriceWithoutDiscount = () => {
    return (
      Number(isPurchased ? resalePrice : rentalPrice?.price) +
      Number(selectedDeliveryAmount) +
      Number(isPurchased ? 0 : stressFreePrice)
    );
  };

  const calculateTransactionFee = () => {
    return parseFloat(calculateTotalPrice() * 0.025).toFixed(2);
  };

  const discountApplied = calculateTotalPriceWithoutDiscount() - calculateTotalPrice();

  const createIntent = () => {
    const value = parseFloat(calculateTotalPrice() + Number(calculateTransactionFee())) * 100;
    props.StripeActions.CreateUserPaymentIntent({
      amount: value === 0 ? 30 : value,
      currency: "GBP",
      reservationId: currentReservation.id,
      discountedAmount:
        parseFloat(
          discountApplied > 0 ? discountApplied : 0 + Number(discount.discountValue)
        ).toFixed(2) * 100,
      deliveryCharges: parseFloat(selectedDeliveryAmount).toFixed(2) * 100,
      damageProtectionFee: parseFloat(stressFreePrice).toFixed(2) * 100,
      transactionFee: calculateTransactionFee() * 100,
      rentalPrice: parseFloat(rentalPrice?.price).toFixed(2) * 100,
      isPurchased,
      resalePrice: parseFloat(resalePrice).toFixed(2) * 100,
    }).then((data) => {
      setClientSecret(data.data.paymentIntentSecret);
      Common.closeLoaderIn3Seconds();
    });
  };

  useEffect(() => {
    if (selectedDeliveryAmount !== null && selectedDeliveryAmount !== undefined) {
      console.log("selectedDeliveryAmount", selectedDeliveryAmount);
      createIntent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeliveryAmount, stressFreePrice, useReferralCredit, discount.discountValue]);

  // set state variable on each change
  const onChangeValue = (name, value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleCoupon = (discountCode) => {
    if (discountCode) {
      props.StripeActions.verifyCouponOrReferralCode(
        discountCode,
        currentReservation.id,
        userId,
        rentalPrice?.price
      ).then((response) => {
        if (response.success) {
          if (response?.discountType !== "Gift Card") {
            if (rentalPrice <= 30) {
              setDiscountMessage({
                message: "Referral or discount code cannot be applied to rentals less than £30",
                success: false,
              });
              return;
            }
          }
          if (response?.discountType === "Gift Card" && response?.data === 0) {
            setDiscountMessage({
              show: true,
              isError: true,
              text: "Voucher already used!",
            });
            return;
          }
          setDiscount({
            discountValue: response?.data,
            discountCode,
            type: "code",
            discountType: response?.discountType,
          });
          setDiscountMessage({
            show: true,
            isError: false,
            text: "Discount Code applied successfully!",
          });
          setUseReferralCredit(false);
          sendAmplitudeData("User used discount code", {
            "Event": "Verified and discount given",
            "discount code": discount,
          });
        } else {
          setDiscount({ discountCode: "", discountValue: 0, type: "code" });
          setDiscountMessage({
            show: true,
            isError: true,
            text: response?.message ? response.message : "Oops, sorry that code didn't work!",
          });
          sendAmplitudeData("User used invalid discount code", {
            "Event": "Invalid discount code",
            "discount code": discount,
          });
        }
      });
    } else {
      setDiscount({ discountCode: "", discountValue: 0, type: "code" });
    }
  };

  const handleDeliveryRadioChange = (e) => {
    const id = Number(e.target.id);
    const label = deliveryOptions.find((item) => item.id === id).option;
    const amount = deliveryOptions.find((item) => item.id === id).value;
    setSelectedDelivery(id);
    setSelectedDeliveryText(label);
    setSelectedDeliveryAmount(amount);
  };

  const requestRentalClick = (paymentIntent) => {
    let discountVal;
    if (discount?.discountType === "Gift Card") {
      discountVal = discountApplied > 0 ? discountApplied : 0;
    } else {
      discountVal = discount?.discountValue;
    }

    const dataObj = {
      name: state.name,
      daCountry: state.daCountry,
      daAddressLine1: state.daAddressLine1,
      daAddressLine2: state.daAddressLine2,
      daTown: state.daTownOrCity,
      daPostalCode: state.daPostalCode,
      paymentIntentId: paymentIntent.id,
      userId: userId,
      deliveryOptionsId: selectedDelivery,
      // nameOnCard: values.nameOnCard,
      country: state.billingCountry,
      addressLine1: state.billingAddressLine1,
      addressLine2: state.billingAddressLine2,
      town: state.billingTownOrCity,
      postalCode: state.billingPostalCode,
      amount: rentalPrice?.price,
      renterFeeCharged: "2.5%",
      renterFee: calculateTransactionFee(),
      totalAmount: parseFloat(calculateTotalPrice() + Number(calculateTransactionFee())).toFixed(2),
      swishedProductId: productId,
      ReservationDetailsId: currentReservation.id,
      PaymentMethodId: paymentIntent.payment_method,
      DiscountCode: discount.discountCode,
      DiscountedAmount: discountVal,
      isAvailingDamageProtection: selectedStressFree === "yes",
      damageProtectionFee: stressFreePrice,
      isUseReferralCreditRequest: useReferralCredit,
    };

    props.StripeActions.CreateStripePaymentDetails(dataObj).then((response) => {
      if (response.success) {
        sendAmplitudeData("Creation of Stripe Payment Details", {
          Event: "User Completed Stripe Payment",
        });
        success.current = true;
        navigate("/payment-successful", {
          state: {
            amount: parseFloat(rentalPrice?.price).toFixed(2),
            orderRef: response.data.id + "-" + productId,
            voucher: response.data.id,
            isPurchased,
          },
        });
      }
    });
  };

  // fetch refer details
  const fetchReferralDetails = () => {
    props.StripeActions.getMyReferralDetails({ userId })
      .then((response) => {
        console.log("refferalDetail api response: ", response);
        const data = response?.data?.data;
        if (data) {
          const { userRefferalBalance, referralDiscountAmount } = data;

          if (userRefferalBalance > 0 && userRefferalBalance >= referralDiscountAmount) {
            setReferralDiscount({
              discountValue: referralDiscountAmount,
              discountCode: "",
            });
          }

          setUserReferralCredit(userRefferalBalance);
        }
      })
      .catch((error) => {
        console.log("Unable to fetch refferalDetail", error);
      });
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="checkoutWrapper">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <h3>Secure checkout</h3>
          </Col>
        </Row>
        <Row className="stripeMainWrapper">
          <Col xs={12} lg={6} className="deliveryDetailMobile">
            <DeliveryDetail
              selectedDelivery={selectedDelivery}
              setSelectedDelivery={setSelectedDelivery}
              setSelectedDeliveryText={setSelectedDeliveryText}
              setSelectedDeliveryAmount={setSelectedDeliveryAmount}
              onChangeValue={onChangeValue}
              isBillingAddress={isBillingAddress}
              setIsBillingAddress={setIsBillingAddress}
              deliveryOptions={deliveryOptions}
              handleDeliveryRadioChange={handleDeliveryRadioChange}
              deliveryErrors={deliveryErrors}
              isPurchased={isPurchased}
            />

            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm
                  state={state}
                  productId={productId}
                  requestRentalClick={requestRentalClick}
                  StripeActions={props.StripeActions}
                  totalChargeable={parseFloat(
                    calculateTotalPrice() + Number(calculateTransactionFee())
                  ).toFixed(2)}
                  reservationId={currentReservation.id}
                  billingCountry={isBillingAddress ? state.billingCountry : state.daCountry}
                  billingPostalCode={
                    isBillingAddress ? state.billingPostalCode : state.daPostalCode
                  }
                  isBillingAddress={isBillingAddress}
                  setDeliveryErrors={setDeliveryErrors}
                  additionalInfo={{
                    discountedAmount: parseFloat(discount.discountValue).toFixed(2) * 100 || 0,
                    deliveryCharges: parseFloat(selectedDeliveryAmount).toFixed(2) * 100 || 0,
                    damageProtectionFee: parseFloat(stressFreePrice).toFixed(2) * 100 || 0,
                    transactionFee: calculateTransactionFee() * 100,
                    rentalPrice: parseFloat(rentalPrice?.price).toFixed(2) * 100,
                    resalePrice: parseFloat(resalePrice).toFixed(2) * 100,
                  }}
                  isPurchased={isPurchased}
                />
              </Elements>
            )}
          </Col>
          <Col xs={12} lg={6} className="rentalDetailMobile">
            <Rentaldetail
              productName={productName}
              productPicture={productPicture}
              productSize={productSize}
              arriveDate={arriveDate}
              arriveMonth={arriveMonth}
              postbackDate={postbackDate}
              postbackMonth={postbackMonth}
              rentalPrice={rentalPrice}
              selectedDeliveryText={selectedDeliveryText}
              selectedDeliveryAmount={selectedDeliveryAmount}
              calculatedStressFreePrice={calculatedStressFreePrice}
              stressFreePrice={stressFreePrice}
              selectedStressFree={selectedStressFree}
              setStressFreePrice={setStressFreePrice}
              setSelectedStressFree={setSelectedStressFree}
              discountPrice={(discountApplied > 0 ? discountApplied : 0) * -1}
              transactionFee={calculateTransactionFee()}
              totalChargeable={parseFloat(
                calculateTotalPrice() + Number(calculateTransactionFee())
              ).toFixed(2)}
              handleCoupon={handleCoupon}
              deliveryOptions={deliveryOptions}
              handleDeliveryRadioChange={handleDeliveryRadioChange}
              selectedDelivery={selectedDelivery}
              discountMessage={discountMessage}
              setDiscountMessage={setDiscountMessage}
              isPurchased={isPurchased}
              resalePrice={resalePrice}
              useReferralCredit={useReferralCredit}
              setDiscount={setDiscount}
              discount={discount}
              referralDiscount={referralDiscount}
              setUseReferralCredit={setUseReferralCredit}
              userReferralCredit={userReferralCredit}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ app, product, delivery }) => {
  const { deliveryOptions } = delivery;
  const { currentReservation } = product;
  const { userId } = app;
  return { currentReservation, userId, deliveryOptions };
};

const mapDispatchToProps = (dispatch) => {
  return {
    StripeActions: bindActionCreators(StripeActions, dispatch),
    ProductActions: bindActionCreators(ProductActions, dispatch),
    DeliveryOptionsActions: bindActionCreators(DeliveryOptionsActions, dispatch),
  };
};

Checkout.propTypes = {
  currentReservation: PropTypes.any,
  userId: PropTypes.any,
  deliveryOptions: PropTypes.any,
  DeliveryOptionsActions: PropTypes.any,
  ProductActions: PropTypes.any,
  StripeActions: PropTypes.any,

};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
