export const MAX_IMAGES = 4;
export const MAX_IMAGES_ERROR_MSG = "Maximum of 4 images allowed";
export const MAX_IMAGE_SIZE = 24000000;
export const MAX_IMAGE_SIZE_ERROR_MSG = "Maximum image size: 24MB";
export const IMAGE_SELECTION_ERROR_MSG = "Unable to select image";
export const GALLERY_PERMISSION_ERROR_MSG = "Swished need permission to access your gallery";

export const SERVER_ERROR = "Server Error";
export const TITLE = "Brand";
export const TITLE_PLACEHOLDER = "Brand";
export const DESCRIPTION_TITLE = "Description";
export const DESCRIPTION_PLACEHOLDER =
  "For example, House Of CB Adrienne Dress, cut from high quality satin with integral boned corset support.";
export const DESCRIPTION_NUMBER_OF_LINES = 5;
export const FIT_LABEL = "Tell us more (optional)";
export const FIT_PLACEHOLDER = "e.g. Fits perfectly for size 10";
export const CONDITION_LABEL = "Tell us more";
export const CONDITION_PLACEHOLDER =
  "Please describe any snags and imperfections, no matter how small";
export const NAVIGATE_BACK_DELAY = 500;
export const PERMISSION_DIALOG_BOX_TITLE = "Access to photos";
export const PERMISSION_DIALOG_BOX_TEXT = "Swished would like to access your photos on your device";
export const NO_USER_INFO = "No user information found";
export const MAKE_SURE_LOGIN = "Make sure you are logged in";
export const CANT_MSG_SAME_USER = "Looks like its your own product";
export const GUEST_USER_MSG = "Please sign in or register to access this feature. \n";

// connection error
export const NETWORK_CONNECTION_ERROR_MSG = "Please check your connection and try again";
export const API_ERROR_MSG = "There is a problem connecting to Swished, please try again later";
export const STRIPE_AUTHORIZE_ERROR = "Unable to authorize the payment";
export const RENT_NOW_SUCCESS =
  "Your request has been sent. Listers usually respond within a few hours. If your rental is urgent, please message the lister directly.";
export const GUEST_USER_IDENTITY = "Guest user";

export const SELECTED_OPTIONS_TYPES = {
  TITLE: "title",
  DESCRIPTION: "description",
  SIZE: "size",
  FIT: "fit",
  FIT_DESCRIPTION: "fitDescription",
  CATEGORY: "category",
  COLOUR: "colour",
  CONDITION: "condition",
  CONDITION_DESCIPTION: "conditionDescription",
  RENTAL_PRICE: "rentalPrice",
  RETAIL_PRICE: "retailPrice",
  RESALE_PRICE: "resalePrice",
  PRODUCT_CLEAN_STATUS: "isProductClean",
  TERM_AND_CONDITION: "isTermAndCondition",
  IMAGES: "images",
};

export const LISTING_MODES = {
  NEW_LISTING: "NEW_LISTING",
  EDIT_LISTING: "EDIT_LISTING",
};

export const LABEL_TYPES = {
  TEXT: "TEXT",
  NODE: "NODE",
};

export const BOOKING_STATUS = {
  REQUESTED: "requested",
  NEW_REQUEST: "newRequest",
  BOOKED: "booked",
  TO_POST: "toPost",
  LIVE: "live",
  COMPLETED: "completed",
  DECLINED: "declined",
};

export const ENV_NAMES = {
  DEVELOPMENT: "development",
  STAGING: "staging",
  PRODUCTION: "production",
};

export const ASYNC_KEYS = {
  LOGGEDIN_USER: "loggedInUser",
  USER_PROFILE: "userProfile",
  PRIMER_SCREEN_ALREADY_SHOWN: "primerScreenAlreadyShown",
};

export const CALENDAR_RESERVATIONS = {
  TOTAL_BOOKING_DAYS: 4,
  TOTAL_CLEANING_DAYS: 3,
};

// rental price variants
export const RENTAL_PRICE_VARIANTS = {
  FOUR_DAYS_PERCENTAGE: 100,
  EIGHT_DAYS_PERCENTAGE: 25,
  TWELVE_DAYS_PERCENTAGE: 55,
  EIGHTEEN_DAYS_PERCENTAGE: 100,
};

// sort options for shopfront

const SORT_OPTIONS = {
  // eslint-disable-next-line quotes
  latest: `What's new`,
  highToLow: "High to low",
  lowToHigh: "Low to high",
  recommended: "Position",
};

export const MENU_OPTIONS = [
  // eslint-disable-next-line quotes
  { title: `What's new?`, value: SORT_OPTIONS.latest },
  { title: "High to low", value: SORT_OPTIONS.highToLow },
  { title: "Low to high", value: SORT_OPTIONS.lowToHigh },
  { title: "Recommended", value: SORT_OPTIONS.recommended },
];

export const DAMAGE_PROTECTION_OPTIONS = [
  // eslint-disable-next-line quotes
  {
    id: 1,
    selected: false,
    option: "Yes, please",
    coverage: true, // important field do not remove unless understand properly
    percentage: 10, // 10 % of the rental value
    maxLimit: 5, // if rental percantage exceed then this amount will be applied
  },
  {
    id: 2,
    selected: true,
    option: "No, I won't need it",
    coverage: false, // important field do not remove unless understand properly
  },
];

export const MEDIA_TYPES = {
  VIDEO: "VIDEO",
  IMAGE: "IMAGE",
};
