import React from "react";
import { Col, Container, Form, Image, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { format, addMonths } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import config from "../../../axios/config";
import { logErrorToGrayLogs } from "../../../redux/actions/GrayLogActions";
import GiftPageHeader from "../../../images/swished-gift-card.png";
import { giftCardForm } from "./validationSchema";
import * as AppActions from "./../../../redux/actions/GiftCardActions";
import GiftCardPayment from "./GiftCardPayment";
import PropTypes from "prop-types";

const stripePromise = loadStripe(
  "pk_live_51KYT5kE1UVqYEWXV20YpqgQ0tK7rvwhVgZhnDgOpzsY2izLtrK7sJjfKhrIOBsu1P55BpWCZ1gjLJTSAKyPZnVoO00Tb5M6XKY"
);

const PurchaseGiftCard = (props) => {
  const [shouldPurchaseGiftCard, setShouldPurchaseGiftCard] = React.useState(false);
  const [giftCardAmounts, setGiftCardAmounts] = React.useState({});
  const [callStripeFunc, setCallStripeFunc] = React.useState(false);
  const [options, setOptions] = React.useState({
    clientSecret: null,
    appearance: {
      theme: "stripe",
    },
  });

  const { control, handleSubmit, reset, trigger } = useForm({
    mode: "all",
    resolver: yupResolver(giftCardForm),
  });

  const handleCaptchaChange = React.useCallback((value) => {
    logErrorToGrayLogs(`function: RegisterUser Captcha, platform: Web, Entered Values : ${value}`);
    if (value) {
      setShouldPurchaseGiftCard(true);
    }
  }, []);

  //:- collecting stripe data for payment processing
  React.useEffect(() => {
    if (props.stripeIntent?.paymentIntentSecret) {
      setOptions((optionsState) => {
        return {
          ...optionsState,
          clientSecret: props.stripeIntent?.paymentIntentSecret,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stripeIntent]);

  //:- fetching gift card amounts
  const getGiftCardAmount = React.useCallback(async () => {
    const { success, data } = await props.GiftCardActions.getGiftCardAmountList();
    if (!success && data.length === 0) {
      toast.error("Unable to fetch gift card amount from server", {
        className: "toastError",
        position: toast.POSITION.TOP_CENTER,
      });
      logErrorToGrayLogs(`function: GetGiftCardAmount, platform: Web`);
    }
    return data || {};
  }, [props.GiftCardActions]);

  React.useEffect(() => {
    getGiftCardAmount().then((res) => {
      //:- Populate form entries if the page is refreshed
      const giftCardStringified = window.sessionStorage.getItem("giftCard");
      const giftCard = giftCardStringified ? JSON.parse(giftCardStringified) : {};

      if (Object.keys(giftCard).length > 0) {
        reset({
          ...giftCard,
          giftAmount: giftCard.giftCardId.toString(),
          deliveryDate: format(new Date(giftCard.deliveryDate), "yyyy-MM-dd"),
        });
        trigger();
      }

      setGiftCardAmounts(res);
    });

    return () => {
      props.GiftCardActions.clearPaymentIntent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //:- Saving data of giftCard and generating stripe intent
  const saveGiftData = async (formValues) => {
    const {
      giftAmount: giftAmountId,
      senderName,
      senderEmail,
      message,
      recipientName,
      recipientEmail,
      deliveryDate,
    } = formValues;
    const utcDate = new Date(deliveryDate);
    const localDate = new Date();
    utcDate.setUTCHours(localDate.getUTCHours(), localDate.getUTCMinutes(), localDate.getUTCSeconds(), localDate.getUTCMilliseconds());
    const giftCard = {
      amount: giftCardAmounts[giftAmountId],
      currency: "GBP",
      giftCardId: giftAmountId,
      senderName: senderName,
      senderEmail: senderEmail,
      userId: props?.userId,
      message,
      recipientName,
      recipientEmail,
      deliveryDate: utcDate,
    };
    window.sessionStorage.setItem("giftCard", JSON.stringify(giftCard));
    const { success } = await props.GiftCardActions.postGetGiftCardPaymentIntent(giftCard);
    if (!success) {
      toast.error("Something went wrong, please contact Swished if the issue persists.", {
        className: "toastError",
        position: toast.POSITION.TOP_CENTER,
      });
      logErrorToGrayLogs(`function: PostGetGiftCardPaymentIntent, platform: Web`);
    }
  };

  //:- Submitting form and getting stripe payment intent
  const createGiftCard = async (formValues) => {
    if (props.stripeIntent?.paymentIntentSecret) {
      setCallStripeFunc(true);
    } else {
      await saveGiftData(formValues);
    }
  };

  return (
    <Container>
      <Row>
        <Col xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
          <h2 style={{ marginTop: 24 }}>Swished Gift Card</h2>

          <Row style={{ justifyContent: "center", marginInline: 0 }} className="signinWrapper">
            <Col xs={9} md={7} lg={6}>
              <Image
                width="100%"
                height={500}
                src={GiftPageHeader}
                className="my-3"
                alt="Header Image"
                thumbnail
              />
            </Col>
            <Col xs={12} md={12} lg={6}>
              {/* Purchase Gift Card Form */}
              <Form
                style={{ textAlign: "left" }}
                id="stripe-process-payment"
                className="loginWrapper mx-lg-8"
                onSubmit={handleSubmit(createGiftCard)}
              >
                <h3>Step 1</h3>
                <Controller
                  name="giftAmount"
                  control={control}
                  defaultValue={0}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <div style={{ marginBottom: 35, display: "flex", flexDirection: "column" }}>
                      <label style={{ marginBottom: 10, fontWeight: "bold", fontSize: 14 }}>
                        Voucher Amount
                      </label>
                      <ToggleButtonGroup
                        type="radio"
                        name="giftAmount"
                        value={value}
                        onChange={onChange}
                        as={Row}
                        style={{ marginInline: "-3px", display: "flex" }}
                      >
                        {typeof giftCardAmounts === "object" && giftCardAmounts !== undefined
                          ? Object.keys(giftCardAmounts).map((amountKey) => (
                            <ToggleButton
                              key={amountKey}
                              variant="outline-secondary"
                              id={`amount-${amountKey}`}
                              value={amountKey}
                              className="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2"
                              style={
                                //:- Checking for active button and changes style
                                value === amountKey
                                  ? {
                                    flex: "1 1 0px",
                                    margin: 5,
                                    borderRadius: ".25rem",
                                    backgroundColor: "#ff5a08",
                                    borderColor: "#ff5a08",
                                    color: "white",
                                  }
                                  : {
                                    flex: "1 1 0px",
                                    margin: 5,
                                    borderRadius: ".25rem",
                                    borderColor: "black",
                                    backgroundColor: "white",
                                    color: "black",
                                  }
                              }
                            >
                              £{giftCardAmounts[amountKey]}
                            </ToggleButton>
                          ))
                          : null}
                      </ToggleButtonGroup>

                      <span className="formErrorMsg">{error?.message}</span>
                    </div>
                  )}
                />

                <h3>Step 2</h3>
                <h5 style={{ fontWeight: "bold", fontSize: ".8rem" }}>To:</h5>
                <Controller
                  name="recipientName"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <div className="did-floating-label-content">
                      <input
                        type="text"
                        className="did-floating-input"
                        placeholder=" "
                        {...field}
                      />
                      <label className="did-floating-label">Recipient's Name</label>
                      <span className="formErrorMsg">{error?.message}</span>
                    </div>
                  )}
                />

                <Controller
                  name="recipientEmail"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <div className="did-floating-label-content">
                      <input
                        type="email"
                        className="did-floating-input"
                        placeholder=" "
                        {...field}
                      />
                      <label className="did-floating-label">Recipient's Email Address</label>
                      <span className="formErrorMsg">{error?.message}</span>
                    </div>
                  )}
                />

                <h5 style={{ fontWeight: "bold", fontSize: ".8rem" }}>From:</h5>
                <Controller
                  name="senderName"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <div className="did-floating-label-content">
                      <input
                        type="text"
                        className="did-floating-input"
                        placeholder=" "
                        {...field}
                      />
                      <label className="did-floating-label">Your Name</label>
                      <span className="formErrorMsg">{error?.message}</span>
                    </div>
                  )}
                />

                <Controller
                  name="senderEmail"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <div className="did-floating-label-content">
                      <input
                        type="text"
                        className="did-floating-input"
                        placeholder=" "
                        {...field}
                      />
                      <label className="did-floating-label">Your Email</label>
                      <span className="formErrorMsg">{error?.message}</span>
                    </div>
                  )}
                />

                <Controller
                  name="deliveryDate"
                  control={control}
                  defaultValue={format(new Date(), "yyyy-MM-dd")}
                  render={({ field, fieldState: { error } }) => (
                    <div className="did-floating-label-content">
                      <input
                        type="date"
                        className="did-floating-input"
                        placeholder=" "
                        min={format(new Date(), "yyyy-MM-dd")}
                        max={format(addMonths(new Date(), 2), "yyyy-MM-dd")}
                        style={{
                          WebkitAppearance: 'textfield',
                          MozAppearance: 'textfield',
                        }}
                        {...field}
                      />
                      <label className="did-floating-label">Delivery Date</label>
                      <span className="formErrorMsg">{error?.message}</span>
                    </div>
                  )}
                />

                <Controller
                  name="message"
                  control={control}
                  defaultValue="I've given you access to a wardrobe with thousands of clothes! Can't wait to see what you rent."
                  render={({ field, fieldState: { error } }) => (
                    <div className="did-floating-label-content">
                      <textarea
                        style={{ resize: "none" }}
                        className="did-floating-input"
                        {...field}
                        rows={4}
                      />
                      <label className="did-floating-label">Personal Message</label>
                      <span className="formErrorMsg">{error?.message}</span>
                    </div>
                  )}
                />

                {options.clientSecret !== null ? (
                  <>
                    <Elements options={options} stripe={stripePromise}>
                      <h3>Step 3</h3>
                      <GiftCardPayment
                        callStripeFunc={callStripeFunc}
                        clientSecret={options.clientSecret}
                      />
                    </Elements>
                    <ReCAPTCHA
                      style={{ marginTop: 30 }}
                      onChange={handleCaptchaChange}
                      sitekey={config.REST_API.GoogleCaptcha.siteKey}
                    />
                  </>
                ) : null}

                <span className="appBtnWrapperInner" style={{ marginBottom: "2rem" }}>
                  <input
                    disabled={options.clientSecret ? !shouldPurchaseGiftCard : false}
                    type="submit"
                    value="Pay Securely Now"
                  />
                </span>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ app, gift }) => ({
  isLoggedIn: app.isLoggedIn,
  userId: app.userId,
  stripeIntent: gift.stripeIntentData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    GiftCardActions: bindActionCreators(AppActions, dispatch),
  };
};

PurchaseGiftCard.propTypes = {
  isLoggedIn: PropTypes.any,
  userId: PropTypes.any,
  stripeIntent: PropTypes.any,
  GiftCardActions: PropTypes.any,
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseGiftCard);
