import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import FloatingLabelInput from "../common/FloatingLabelInput";
import AppButton from "../common/AppButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import * as AppActions from "../../redux/actions/AppActions";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import {
  sendAmplitudeData,
  setAmplitudeUserId,
  setAmplitudeUserProperties,
} from "../../../utils/amplitude";
import { computeHash, generateRandomString } from "../../../utils/generateHash";
import PropTypes from "prop-types";

const Login = (props) => {
  const { returnPdpPath } = props;
  const [isPassword, setIsPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const navigate = useNavigate();

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const validateInputs = (state) => {
    if (!state.email) {
      return { email: { message: "Email is required" } };
    } else if (!state.password) {
      return { password: { message: "Password is required" } };
    }
    return null;
  };

  const handleLoginResponse = (response, userProperties) => {
    if (response.success) {
      setLoginError({ email: { message: "" }, password: { message: "" } });

      if (response.userId) {
        toast.error(response.message, {
          className: "toastError",
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/register", { state: { userId: response.userId } });
      } else {
        returnPdpPath ? navigate(returnPdpPath) : navigate("/");
        setAmplitudeUserId(response.data.user.userId);
        sendAmplitudeData("Sign_In-Success", { Event: "User signs in" });
        setAmplitudeUserProperties(userProperties);
      }
    } else {
      sendAmplitudeData("Sign_In-Fail", {
        "Event": "User fails to sign in",
        "Reason for failure": response.message,
      });
      setLoginError({
        email: { message: "Invalid Email" },
        password: { message: "Invalid Password" },
      });
    }
  };

  const onLoginClick = () => {
    const validationErrors = validateInputs(state);
    if (validationErrors) {
      setLoginError(validationErrors);
      return;
    }

    const hashValue = generateRandomString();
    const hash = computeHash(hashValue);

    props.AppActions.login({
      ...state,
      hash,
      value: hashValue,
      isWeb: true,
    }).then((response) => handleLoginResponse(response, { Email: state.email }));
  };

  const handleChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };
  return (
    <div className="signinWrapper">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <div className="loginWrapper">
              <h3>Let's get you signed in</h3>
              <FloatingLabelInput
                type="text"
                label="Email"
                name="email"
                value={state.email}
                onchange={handleChange}
                autoComplete="off"
                error=""
              />
              <p className="formErrorMsg">{loginError.email?.message}</p>
              <span className="loginPasswordEyeWrapper">
                {isPassword ? (
                  <AiFillEye onClick={() => setIsPassword(!isPassword)} />
                ) : (
                  <AiOutlineEye onClick={() => setIsPassword(!isPassword)} />
                )}
                <FloatingLabelInput
                  type={`${isPassword ? "text" : "password"}`}
                  label="Password"
                  name="password"
                  value={state.password}
                  onchange={handleChange}
                  autoComplete="off"
                  error=" "
                />
                <p className="formErrorMsg">{loginError.password?.message}</p>
              </span>

              <span className="loginTermsandCondition">
                <Row>
                  <Col md={8}>
                    {/* <input type="checkbox" id="agreement" name="agreement" value="" /> */}
                    <label htmlFor="agreement">
                      {" "}
                      By continuing, you agree to Swished's{" "}
                      <Link to="/terms-of-services">Terms of Use</Link> and have read our{" "}
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </label>
                  </Col>
                  <Col>
                    <Link to="/forgotpassword">Forgot password?</Link>
                  </Col>
                </Row>
              </span>

              <span className="appBtnWrapperInner">
                <AppButton text="Login" onclick={() => onLoginClick()} />
              </span>
              <span className="noAccountbtnWrapper">
                Don’t have an account? <Link to="/register">Sign up</Link>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ product }) => {
  const { returnPdpPath } = product;
  return { returnPdpPath };
};

const mapDispatchToProps = (dispatch) => {
  return { AppActions: bindActionCreators(AppActions, dispatch) };
};

Login.propTypes = {
  AppActions: PropTypes.any,
  returnPdpPath: PropTypes.any,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
