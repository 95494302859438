import React from "react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import * as AppActions from "./../../../redux/actions/GiftCardActions";
import { toast } from "react-toastify";

const GiftCardPayment = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  //:- Options passed to PaymentElement
  const paymentElementOptions = {
    wallets: {
      applePay: "never",
      googlePay: "never",
    },
    terms: {
      card: "never",
    },
  };

  //:- performing payment completion
  const performStripePayment = React.useCallback(() => {
    if (!stripe || !elements) {
      console.error("Stripe/elements cannot be initialized");
      toast.error(
        "Unable to perform your payment right now. Please try again, If this problem persists pleace contact swished team",
        {
          className: "toastError",
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }

    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/gift-cards/payment-successful`,
        },
        redirect: "if_required",
      })
      .then(async (res) => {
        const { paymentIntent } = res;
        const giftCardData = {
          paymentIntentId: paymentIntent?.id,
          totalAmount: props.giftData.amount,
          giftCardId: props.giftData.giftCardId,
          buyerName: props.giftData.senderName,
          buyerEmail: props.giftData.senderEmail,
          recipientName: props.giftData.recipientName,
          recipientEmail: props.giftData.recipientEmail,
          reminderDate: props.giftData.deliveryDate,
          personalizedMessage: props.giftData.message,
        };
        const { success } = await props.GiftCardActions.saveGiftCardData(giftCardData);
        if (success) {
          window.sessionStorage.removeItem("giftCard");
          navigate("/gift-cards/payment-successful");
        } else {
          toast.error("Something went wrong, please contact Swished if the issue persists.", {
            className: "toastError",
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, elements]);

  React.useEffect(() => {
    if (props.callStripeFunc) performStripePayment();
  }, [props.callStripeFunc, performStripePayment]);

  return (
    <>
      {stripe !== null || elements !== null ? (
        <PaymentElement id="stripe-process-payment" options={paymentElementOptions} />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ gift }) => ({
  stripeIntent: gift.stripeIntentData,
  giftData: gift.giftCardData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    GiftCardActions: bindActionCreators(AppActions, dispatch),
  };
};

GiftCardPayment.propTypes = {
  GiftCardActions: PropTypes.any,
  giftData: PropTypes.any,
  callStripeFunc: PropTypes.any,
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardPayment);
