import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as AppActions from "../../../redux/actions/InstaActions";
import { InstagramGallery } from "instagram-gallery";
import PropTypes from "prop-types";

const InstaFeed = (props) => {
  useEffect(() => {
    props.AppActions.getInstaAccessToken();
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as per your needs
    };

    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const count = isMobile ? 6 : 8;

  return (
    <div className="instaFeedWrapper">
      <InstagramGallery
        accessToken="IGQWROZA0JWLU54bF9IOWFNZA2szSkE2RFNpWlNraTNKU0JGZAE80cjdwWHNqeTBhWm15TkRScnBuVHIzdWUzVHg1QVgxWmRoLUw3eFQ3X1habXRScjZABNk5taTFlY21fUEFIVS1JQjFrSm9JajRXU3ZANajRMOTB3QU0ZD"
        count={count}
      />
    </div>
  );
};

const mapStateToProps = ({ app }) => {
  const { insta_access_token } = app;
  return { insta_access_token };
};

const mapDispatchToProps = (dispatch) => {
  return { AppActions: bindActionCreators(AppActions, dispatch) };
};

InstaFeed.propTypes = {
  AppActions: PropTypes.any,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstaFeed);
