/* global AWIN */
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import QrCode from "./QrCode";
import { googleplaybtn, appstorebtn } from "../../../images/index";
import * as productActions from "../../../redux/actions/productActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const PaymentSuccessful = (props) => {
  const location = useLocation();
  const isPurchased = location.state.isPurchased;

  useEffect(() => {
    window.scrollTo(0, 0);
    props.productActions.setReturnPdpPath("");
  }, []);

  useEffect(() => {
    if (typeof AWIN != "undefined" && typeof AWIN.Tracking != "undefined") {
      // AWIN.Tracking.setCookie("awc", "66996633")
      AWIN.Tracking.Sale = {};
      AWIN.Tracking.Sale.amount = location?.state?.amount;
      AWIN.Tracking.Sale.channel = "aw";
      AWIN.Tracking.Sale.orderRef = location?.state?.orderRef;
      AWIN.Tracking.Sale.parts = "DEFAULT:" + location?.state?.amount;
      AWIN.Tracking.Sale.currency = "GBP";
      AWIN.Tracking.Sale.voucher = location?.state?.voucher;
      AWIN.Tracking.Sale.test = "0";
      AWIN.Tracking.run();
    }
  }, []);

  return (
    <div className="paymentWrapper">
      <Container>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <h3>
              Your{" "}
              {isPurchased
                ? "purchase request has been sent to the lister"
                : "rental has been requested"}
            </h3>
            <p>
              We've let the lister know you'd like to {isPurchased ? "buy" : "rent"} their item.
              Listers have 48 hours to respond, but if your request is urgent, to message the lister
              directly or to view the status of your request please download the Swished app.{" "}
            </p>
            <p>Click on this image from your phone or scan the QR code to download now.</p>

            <span className="playstoreWrapper">
              <span className="downloadBtn"> CLICK HERE TO DOWNLOAD NOW </span>
              <div className="QeCodeWrapper">
                <span>
                  <a
                    href="https://apps.apple.com/gb/app/swished/id1600685718"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appstorebtn} alt="" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=co.uk.swished"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={googleplaybtn} alt="" />
                  </a>
                </span>
                <span>
                  <QrCode />
                </span>
              </div>
            </span>
          </Col>
        </Row>
        <img
          src="`https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=51175&amount=${location?.state?.amount}&cr=GBP&ref=${location?.state?.orderRef}&parts=DEFAULT:${location?.state?.amount}&vc=${location?.state?.voucher}&ch=aw&customeracquisition=`"
          border="0"
          width="0"
          height="0"
          alt="success"
        ></img>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    productActions: bindActionCreators(productActions, dispatch),
  };
};

PaymentSuccessful.propTypes = {
  productActions: PropTypes.any,
}

export default connect(null, mapDispatchToProps)(PaymentSuccessful);
