import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import RentallTile from "../../common/RentallTile";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import * as ListingActions from "../../../redux/actions/ListingActions";
import { dummyUser } from "../../../images/index";
import { getCategorySlug } from "../../../utilities/helpers";
import { useNavigate } from "react-router-dom";
import { convertTitleToSlug } from "../../../../utils/utilities";
import PropTypes from "prop-types";

const SwishedEdit = (props) => {
  const userId = useSelector((state) => state.app.userId);
  const [isFavorit, setIsFavorit] = useState(false);
  const navigate = useNavigate();
  const { recommendedProducts } = props;
  useEffect(() => {
    props.ListingActions.getRecommendedProducts(userId);
  }, []);
  const IsFavorite = () => {
    setIsFavorit(true);
    alert("ok, Done!!!");
  };

  return (
    <div className="productTilesGridWrapper contentBodyInner">
      <Container>
        <Row>
          <span className="subHeading">.Swished Edit</span>
          {recommendedProducts?.map((item, index) => {
            return (
              <Col lg={4} xl={3} md={6} xs={6} key={index} className="mobileTiles">
                <RentallTile
                  picture={item.fields.src || dummyUser}
                  title={item.fields.title}
                  size={item.fields.size}
                  dress={item.fields.category}
                  rrp={item.fields.retailprice}
                  price={item.fields.rentalprice}
                  isInMyWishList={item?.isInMyWishList}
                  color={item.fields.colour}
                  category={item.fields.category}
                  favorite={IsFavorite}
                  isFavorit={isFavorit}
                  productId={item.id}
                  resalePrice={item.resaleprice}
                  onclick={`/products/${getCategorySlug(item.fields.category)}-${convertTitleToSlug(
                    item.fields.title
                  )}-${item.fields.colour}-${item.id}`}
                />
              </Col>
            );
          })}
          <span className="swishedEditButton">
            <Button onClick={() => navigate("/allrentals")} variant="primary">
              View All
            </Button>
          </span>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ listing }) => {
  const { recommendedProducts } = listing;
  return { recommendedProducts };
};

const mapDispatchToProps = (dispatch) => {
  return { ListingActions: bindActionCreators(ListingActions, dispatch) };
};

SwishedEdit.propTypes = {
  recommendedProducts: PropTypes.any,
  ListingActions: PropTypes.any,
}

export default connect(mapStateToProps, mapDispatchToProps)(SwishedEdit);
