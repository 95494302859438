import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contentBodyInner termsOfServicesWrapper">
      <Container>
        <Row>
          <Col>
            <h3>General requests for refunds</h3>
            <p>
              Items on Swished are owned by individual listers who upload them onto the Swished
              platform. Whether they offer refunds if you change your minds is solely for them to
              decide. Refunds are not issued as standard and Swished has no say on whether a lister
              should offer refunds, we only act as the facilitator of rental transactions. If you
              would like to know a lister's refund policy, please find out directly from them before
              you book the item.
            </p>
            <h3>Damages and issues</h3>
            <p>
              Inspect your order upon receipt and contact the lister immediately on the day it
              arrives if there are any issues. As the items on Swished are rental items, they will
              not be brand new and may have some minor marks, or some sequins and jewels missing.
              This should be considered normal. Swished is here to help extend the life of clothes
              and enable everyone to have access to on trend beautiful items.
            </p>
            <p>
              In some instances, a zip may be difficult to use - which is normal on some items - and
              the lister should be able to help resolve it easily.
            </p>
            <p>
              If however, there is damage that means the item cannot be worn, or it's not clean,
              then you must in the first instance contact the lister and try to resolve the issues
              with them directly.{" "}
            </p>
            <p>
              If you are unable to agree a resolution with the lister, then you can contact Swished
              to open a dispute. To do so, you must:
            </p>
            <ul>
              <li> Contact Swished within 5 days of your rental period starting</li>
              <li>
                {" "}
                Show evidence you contacted the lister on the day you received the item reporting
                the issue
              </li>
              <li> Proof that you and the lister have not been able to agree a resolution</li>
              <li>
                {" "}
                Agree that Swished will attempt to mediate a resolution and that you agree to the
                decision that Swished makes on the outcome
              </li>
            </ul>
            <p>
              We aim to acknowledge all disputes within 24 hours. The best way to contact us if you
              are unable to resolve a dispute is to email us at hello@rentwithswished.com sending an
              explanation of what happened and the evidence set-out above.
            </p>

            <h3>Refunds</h3>
            <p>
              If the lister and Swished agrees to process a refund where the refund request has been
              made 15 or more days before your rental start date, you'll be refunded onto your
              original payment method minus a small admin fee that covers the payment charges
              applied by the payment provider.{" "}
            </p>
            <p>
              If you cancel on or within 14 days of your rental start date, you will be charged £10
              or 10% of the rental value, whichever is greater.{" "}
            </p>
            <p>
              If the lister has already posted the item, a refund may not be possible, and this will
              be reviewed on a case-by-case basis and discussed with the lister. This is at the
              discretion of the lister, not Swished.{" "}
            </p>
            <p>
              Please note it can take a few days for your bank or credit card company to credit your
              refund.{" "}
            </p>
            <p>
              If you have taken out the optional stress-free renting cover, these costs cannot be
              refunded in the event that a rental is cancelled within 14 days.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RefundPolicy;
